<template>
  <div>
    <el-form :model="test" class="tests" label-position="left">
      <!-- ******PSAT****** -->
      <el-row v-if="test.value === 'PSAT'" :gutter="20">
        <el-col :md="24">
          <h3 class="primaryColor">{{ test.name.toUpperCase() }}:</h3>
        </el-col>

        <el-col :span="12" :md="6">
          <el-form-item
            label=""
            prop="reading_score"
            :rules="testRules.psatReading"
          >
            <el-input
              v-model="test.reading_score"
              placeholder="Reading"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12" :md="6">
          <el-form-item
            label=""
            prop="grammar_score"
            :rules="testRules.psatGrammar"
          >
            <el-input
              v-model="test.grammar_score"
              placeholder="Grammar"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12" :md="6">
          <el-form-item label="" prop="math_score" :rules="testRules.psatMath">
            <el-input
              v-model="test.math_score"
              placeholder="Math"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <!-- <el-col :md="2" class="d-none d-md-block">
          <el-form-item></el-form-item>
        </el-col> -->

        <el-col :span="24" :md="24">
          <el-form-item label="Total">
            <el-input
              :value="sumPSAT"
              :disabled="true"
              style="width: 120px"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12" :md="12">
          <el-form-item label="Is this a real test?">
            <el-select
              v-model="test.is_real_test"
              @change="emitToParent"
              placeholder="Please select"
            >
              <el-option
                v-for="item in isRealTestOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="12">
          <el-form-item label="Date of test">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="test.taken_on"
              type="date"
              @change="emitToParent"
              placeholder="Select the date"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- ******SAT_student****** -->
      <el-row v-if="test.value === 'SAT_student'" :gutter="20">
        <el-col :md="2">
          <h3 class="primaryColor">{{ test.name.toUpperCase() }}:</h3>
        </el-col>

        <el-col :span="6" :md="3">
          <el-form-item
            label="Reading"
            prop="reading_score"
            :rules="testRules.satReading"
          >
            <el-input
              v-model="test.reading_score"
              placeholder="Reading"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6" :md="3">
          <el-form-item
            label="Grammar"
            prop="grammar_score"
            :rules="testRules.satGrammar"
          >
            <el-input
              v-model="test.grammar_score"
              placeholder="Grammar"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12" :md="6">
          <el-form-item
            label="Math"
            prop="math_score"
            :rules="testRules.satMath"
          >
            <el-input
              v-model="test.math_score"
              placeholder="Math"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24" :md="24">
          <el-form-item label="Total">
            <el-input :value="sumSAT_student" :disabled="true"></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="2" class="d-none d-md-block">
          <el-form-item></el-form-item>
        </el-col>

        <el-col :span="14" :md="6">
          <el-form-item class="d-inline-block" label="Is this a real test?">
            <el-select
              v-model="test.is_real_test"
              @change="emitToParent"
              placeholder="Please select"
            >
              <el-option
                v-for="item in isRealTestOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="10" :md="6">
          <el-form-item class="d-inline-block" label="Date of test">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="test.taken_on"
              type="date"
              @change="emitToParent"
              placeholder="Select the date"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- ******SAT_teacher****** -->
      <!-- <div v-if="test.value === 'SAT_teacher'">
        <h3 class="primaryColor">{{ test.name.toUpperCase() }}:</h3>
        <div>
          <div class="row">
            <div class="col-sm-3">
              <el-form-item
                label=""
                prop="english_score"
                :rules="testRules.satEnglish"
              >
                <el-input
                  v-model="test.english_score"
                  placeholder="English"
                  @input="emitToParent"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-sm-3">
              <el-form-item
                label=""
                prop="math_score"
                :rules="testRules.satMath"
              >
                <el-input
                  v-model="test.math_score"
                  placeholder="Math"
                  @input="emitToParent"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-sm-3">
              <el-form-item
                label=""
                prop="writing_score"
                :rules="testRules.satEssay"
              >
                <el-input
                  v-model="test.writing_score"
                  placeholder="Essay"
                  @input="emitToParent"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-sm-3">
              <el-form-item label="Total">
                <el-input
                  :value="sumSAT_teacher"
                  :disabled="true"
                  style="width:100px"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row" style="margin:10px 0">
            <div class="col-sm-6">
              <el-form-item label="Is this a real test?">
                <el-select
                  v-model="test.is_real_test"
                  @change="emitToParent"
                  placeholder="Please select"
                  style=""
                >
                  <el-option
                    v-for="item in isRealTestOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-sm-6">
              <el-form-item label="Date of test">
                <el-date-picker  value-format="yyyy-MM-dd"
                  v-model="test.taken_on"
                  type="date"
                  @change="emitToParent"
                  placeholder="Select the date"
                  style="width:200px"
                ></el-date-picker>
              </el-form-item>
            </div>
          </div>
        </div>
      </div> -->
      <div v-if="test.value === 'SAT_teacher'">
        <el-row gutter="20">
          <el-col :md="3">
            <h4 class="primaryColor">{{ test.name.toUpperCase() }}:</h4>
          </el-col>
          <el-col :md="8">
            <el-form-item
              label=""
              prop="english_score"
              :rules="testRules.satEnglish"
            >
              <el-input
                v-model="test.english_score"
                placeholder="English"
                @input="emitToParent"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :md="8">
            <el-form-item label="" prop="math_score" :rules="testRules.satMath">
              <el-input
                v-model="test.math_score"
                placeholder="Math"
                @input="emitToParent"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :md="5">
            <el-input :value="sumSAT_teacher" :disabled="true"></el-input>
          </el-col>
        </el-row>

        <!-- <el-col :md="12">
          <el-form-item label="Is this a real test?">
            <el-select
              v-model="test.is_real_test"
              @change="emitToParent"
              placeholder="Please select"
            >
              <el-option
                v-for="item in isRealTestOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col> -->

        <!-- <el-col :md="12">
          <el-form-item label="Date of test">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="test.taken_on"
              type="date"
              @change="emitToParent"
              placeholder="Select the date"
            ></el-date-picker>
          </el-form-item>
        </el-col> -->
      </div>

      <!-- ******ACT****** -->
      <div v-if="test.value === 'ACT'">
        <el-row :gutter="20">
          <el-col :md="3">
            <h4 class="primaryColor">{{ test.name.toUpperCase() }}:</h4>
          </el-col>
          <el-col :md="4">
            <el-form-item
              label=""
              prop="english_score"
              :rules="testRules.actEnglish"
            >
              <el-input
                v-model="test.english_score"
                placeholder="English"
                @input="emitToParent"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :md="4">
            <el-form-item label="" prop="math_score" :rules="testRules.actMath">
              <el-input
                v-model="test.math_score"
                placeholder="Math"
                @input="emitToParent"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :md="4">
            <el-form-item
              label=""
              prop="science_score"
              :rules="testRules.actScience"
            >
              <el-input
                v-model="test.science_score"
                placeholder="Science"
                @input="emitToParent"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :md="4">
            <el-form-item
              label=""
              prop="reading_score"
              :rules="testRules.actReading"
            >
              <el-input
                v-model="test.reading_score"
                placeholder="Reading"
                @input="emitToParent"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :md="3">
            <el-form-item
              label=""
              prop="writing_score"
              :rules="testRules.actEssay"
            >
              <el-input
                v-model="test.writing_score"
                placeholder="Essay"
                @input="emitToParent"
              ></el-input>
            </el-form-item>
          </el-col> -->

          <el-col :md="5">
            <el-input :value="aveACT" :disabled="true"></el-input>
          </el-col>
        </el-row>

        <!-- <el-col :md="2" class="d-none d-md-block">
          <el-form-item></el-form-item>
        </el-col> -->
      </div>

      <!-- ******AP****** -->
      <div v-if="test.value === 'AP'">
        <DynamicTest
          :test="test"
          :id="idAp"
          :isTeacher="true"
          @dataFromChild="emitToParent"
          @dataFromGrandchild="emitToParent"
        />
      </div>

      <!-- ******IB****** -->
      <div v-if="test.value === 'IB'" style="padding-bottom:30px">
        <DynamicTest
          :test="test"
          :id="idIb"
          :isTeacher="true"
          @dataFromChild="emitToParent"
          @dataFromGrandchild="emitToParent"
        />
      </div>

      <!-- ******TOEFL****** -->
      <el-row v-if="test.value === 'TOEFL'" :gutter="20">
        <el-col :md="3">
          <h4 class="primaryColor">{{ test.name.toUpperCase() }}:</h4>
        </el-col>

        <el-col :md="4">
          <el-form-item
            label=""
            prop="reading_score"
            :rules="testRules.toeflReading"
          >
            <el-input
              v-model="test.reading_score"
              placeholder="Reading"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="4">
          <el-form-item
            label=""
            prop="listening_score"
            :rules="testRules.toeflListening"
          >
            <el-input
              v-model="test.listening_score"
              placeholder="Listening"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="4">
          <el-form-item
            label=""
            prop="speaking_score"
            :rules="testRules.toeflSpeaking"
          >
            <el-input
              v-model="test.speaking_score"
              placeholder="Speaking"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="4">
          <el-form-item
            label=""
            prop="writing_score"
            :rules="testRules.toeflWriting"
          >
            <el-input
              v-model="test.writing_score"
              placeholder="Writing"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="5">
          <el-input :value="sumTOEFL" :disabled="true"></el-input>
        </el-col>
      </el-row>

      <!-- ******IELTS****** -->
      <el-row v-if="test.value === 'IELTS'" :gutter="20">
        <el-col :span="3">
          <h4 class="primaryColor">{{ test.name.toUpperCase() }}:</h4>
        </el-col>

        <el-col :md="4">
          <el-form-item
            label=""
            prop="reading_score"
            :rules="testRules.ieltsReading"
          >
            <el-input
              v-model="test.reading_score"
              placeholder="Reading"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="4">
          <el-form-item
            label=""
            prop="listening_score"
            :rules="testRules.ieltsListening"
          >
            <el-input
              v-model="test.listening_score"
              placeholder="Listening"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="4">
          <el-form-item
            label=""
            prop="speaking_score"
            :rules="testRules.ieltsSpeaking"
          >
            <el-input
              v-model="test.speaking_score"
              placeholder="Speaking"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="4">
          <el-form-item
            label=""
            prop="writing_score"
            :rules="testRules.ieltsWriting"
          >
            <el-input
              v-model="test.writing_score"
              placeholder="Writing"
              @input="emitToParent"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="5">
          <el-input :value="aveIELTS" :disabled="true"></el-input>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import DynamicTest from "@/components/test/DynamicTest";
import testValidationRules from "@/mixins/testValidationRules";
export default {
  props: {
    test: {
      type: [Object],
      required: true
    }
  },
  mixins: [testValidationRules],
  components: {
    DynamicTest
  },
  data() {
    return {
      idAp: 1,
      idIb: 1,
      isRealTestOptions: [
        {
          value: 1,
          label: "Yes"
        },
        {
          value: 0,
          label: "No"
        }
      ]
    };
  },

  methods: {
    emitToParent() {
      this.$emit("dataFromChild", this.test);
    }
  },
  computed: {
    // ******PSAT******
    sumPSAT: function() {
      let total = 0;
      const intPattern = /^\d*$/;
      const tempArray = [
        this.test.reading_score,
        this.test.grammar_score,
        this.test.math_score
      ];

      tempArray.forEach(item => {
        if (item === "") return (total += 0);
        if (!intPattern.test(item)) return (total = 0);
        if (parseFloat(item) < 100 || parseFloat(item) > 800)
          return (total = 0);
        total += parseInt(item);
      });
      if (total > 1600 || total < 400) return (total = 0);
      return total;
    },
    // ******SAT******
    sumSAT_student: function() {
      let total = 0;
      const intPattern = /^\d*$/;
      const tempArray = [
        this.test.reading_score,
        this.test.grammar_score,
        this.test.math_score
      ];

      tempArray.forEach(item => {
        if (item === "") return (total += 0);
        if (!intPattern.test(item)) return (total = 0);
        if (parseFloat(item) < 100 || parseFloat(item) > 800)
          return (total = 0);
        total += parseInt(item);
      });
      if (total > 1600 || total < 400) return (total = 0);
      return total;
    },
    sumSAT_teacher: function() {
      let total = 0;
      const intPattern = /^\d*$/;
      const tempArray = [this.test.english_score, this.test.math_score];

      tempArray.forEach(item => {
        if (item === "") return (total += 0);
        if (!intPattern.test(item)) return (total = 0);
        if (parseFloat(item) < 200 || parseFloat(item) > 800)
          return (total = 0);
        total += parseInt(item);
      });
      if (total > 1600 || total < 400) return (total = 0);
      return total;
    },
    aveACT: function() {
      let total = 0;
      let ave = 0;
      const tempArray = [
        this.test.english_score,
        this.test.math_score,
        this.test.science_score,
        this.test.reading_score
      ];
      const intPattern = /^\d*$/;
      tempArray.forEach(item => {
        if (item === "") return;
        if (!intPattern.test(item)) return 0;
        if (parseFloat(item) < 0 || parseFloat(item) > 36) return 0;
        total += parseInt(item);
      });
      ave = total / 4;
      ave = Math.round(ave * 10) / 10;
      if (ave > 36 || ave < 0) return 0;
      return ave;
    },
    sumTOEFL: function() {
      let total = 0;
      const intPattern = /^\d*$/;
      const tempArray = [
        this.test.reading_score,
        this.test.listening_score,
        this.test.speaking_score,
        this.test.writing_score
      ];
      tempArray.forEach(item => {
        if (item === "") return;
        if (!intPattern.test(item)) return (total = 0);
        if (parseInt(item) < 0 || parseInt(item) > 30) return (total = 0);
        total += parseInt(item);
      });
      return total;
    },
    aveIELTS: function() {
      let total = 0;
      let ave = 0;
      const numPattern = /^\d(\.5)?$/;
      const tempArray = [
        this.test.reading_score,
        this.test.listening_score,
        this.test.speaking_score,
        this.test.writing_score
      ];
      tempArray.forEach(item => {
        if (item === "") return;
        if (!numPattern.test(item)) return 0;
        if (parseFloat(item) < 0 || parseFloat(item) > 9) return 0;
        total += parseFloat(item);
      });
      ave = total / 4;
      ave = ave * 1000;
      let integer = Math.floor(ave / 1000) * 1000;
      let decimal = ave % 1000;
      if (decimal >= 250 && decimal < 750) {
        decimal = 500;
        ave = (integer + decimal) / 1000;
      } else if (decimal < 250) {
        ave = integer / 1000;
      } else if (decimal >= 750) {
        ave = integer / 1000 + 1;
      }

      if (ave > 9 || ave < 0) return 0;
      return ave;
    }
  }
};
</script>

<style scoped>
v-row {
  padding: 0px;
  margin: 0px;
}
v-col {
  padding: 0px;
  margin: 0px;
}
v-layout {
  padding: 0px;
  margin: 0px;
}
v-flex {
  padding: 0px;
  margin: 0px;
}
.primaryColor {
  line-height: 40px;
  color: #42a16a;
}
.tests >>> .el-col {
  margin: 0;
}
.tests h3 {
  margin-bottom: 0;
}
.tests >>> .el-form-item__label {
  margin-bottom: 0;
}
.md-label {
  padding-right: 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
  /* justify-content:center; */
}
.md-label > span {
  display: inline-block;
  padding-right: 5px;
}
</style>

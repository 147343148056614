<template>
  <div style="margin:0px">
    <el-form
      :model="newObjectDetail"
      :rules="rules"
      ref="newObjectDetail"
      label-width="20px"
      @submit.native.prevent
    >
      <!-- <el-row :gutter="20" v-if="false">
        <el-col :sm="6">
          <el-form-item></el-form-item>
        </el-col>
        <el-col :span="12" :sm="12">
          <el-form-item label-width="0px" :error="authCodeError">
            <el-input
              :placeholder="$t('teacherDetail.instructorCode')"
              v-model="instructorCode"
              @focus="colorChangeCode = true"
              @blur="colorChangeCode = false"
            >
              <div
                slot="prefix"
                class="input-icon icon-card"
                :style="
                  colorChangeCode
                    ? 'background-color: #42a16a;'
                    : 'background-color: #757575;'
                "
              ></div>
              <div
                slot="suffix"
                class="input-icon icon-check"
                :style="
                  colorChangeIsValid
                    ? 'background-color: #4CAF50;'
                    : 'background-color: white;'
                "
              ></div>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" :sm="4">
          <el-button type="primary" @click="authCode" style="width:auto">{{
            $t("teacherDetail.validate")
          }}</el-button>
        </el-col>
      </el-row> -->

      <template v-if="isAuthCode">
        <!-- <el-row style="padding-top:20px">
          <el-col>
            <h1 class="titleLine primaryColor">
              {{ $t("teacherDetail.requiredInformation") }}
            </h1>
          </el-col>
        </el-row> -->
        <!-- *****College***** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <div>
              <span style="color:red">* &nbsp;</span>
              {{ $t("teacherDetail.college") }}
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item prop="college" label-width="0px" required>
              <el-input
                :placeholder="$t('teacherDetail.college')"
                v-model="newObjectDetail.college.en"
                @focus="colorChangeSchool = true"
                @blur="colorChangeSchool = false"
              >
                <div
                  slot="prefix"
                  class="input-icon icon-domain"
                  :style="
                    colorChangeSchool
                      ? 'background-color: #42a16a;'
                      : 'background-color: #757575;'
                  "
                ></div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- *****Grade level***** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <div>
              <span style="color:red">* &nbsp;</span>
              {{ $t("teacherDetail.gradeLevel") }}
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0" required>
              <el-select
                v-model="graduateSelected"
                :placeholder="$t('teacherDetail.gradeLevel')"
                style="width:100%"
                @focus="colorChangeGrade = true"
                @blur="colorChangeGrade = false"
              >
                <div
                  slot="prefix"
                  class="input-icon icon-school"
                  :style="
                    colorChangeGrade
                      ? 'background-color: #42a16a;'
                      : 'background-color: #757575;'
                  "
                ></div>
                <el-option
                  v-for="(item, index) in graduateSelection"
                  :key="index"
                  :label="$t(item.label, { year: item.param })"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- *****Major***** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <div>
              <span style="color:red">* &nbsp;</span>
              {{ $t("teacherDetail.major") }}
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0" prop="college_major" required>
              <el-input
                :placeholder="$t('teacherDetail.major')"
                v-model="newObjectDetail.college_major.en"
                @focus="colorChangeMajor = true"
                @blur="colorChangeMajor = false"
              >
                <div
                  slot="prefix"
                  class="input-icon icon-major"
                  :style="
                    colorChangeMajor
                      ? 'background-color: #42a16a;'
                      : 'background-color: #757575;'
                  "
                ></div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- *****Phone***** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <div>
              <span style="color:red">* &nbsp;</span>
              {{ $t("teacherDetail.phone") }}
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0" prop="phone" required>
              <el-input
                :placeholder="$t('teacherDetail.phone')"
                v-model="newObjectDetail.phone"
                @focus="colorChangePhone = true"
                @blur="colorChangePhone = false"
              >
                <div
                  slot="prefix"
                  class="input-icon icon-phone"
                  :style="
                    colorChangePhone
                      ? 'background-color: #42a16a;'
                      : 'background-color: #757575;'
                  "
                ></div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- *****Line***** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <div>
              <span style="color:red">* &nbsp;</span>
              <el-popover placement="top" trigger="hover">
                <div>
                  <el-alert type="success" :closable="false" class="p-1">
                    <div>
                      Please download Line (
                      <a target="_blank" href="https://line.me/"
                        ><b>https://line.me/</b></a
                      >
                      ) and create an<br />
                      account if you don’t already have one. We’ll use Line
                      as<br />
                      the main method of communication.
                    </div>
                  </el-alert>
                </div>
                <span
                  class="text-success"
                  style="cursor:pointer"
                  slot="reference"
                >
                  Line
                  <i class="fas fa-question-circle"></i
                ></span>
              </el-popover>
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0" prop="line_id" required>
              <el-input
                placeholder="Line"
                v-model="newObjectDetail.line_id"
                @focus="colorChangeLine = true"
                @blur="colorChangeLine = false"
              >
                <div
                  slot="prefix"
                  class="input-icon icon-line"
                  :style="
                    colorChangeLine
                      ? 'background-color: #42a16a;'
                      : 'background-color: #757575;'
                  "
                ></div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- *****Add Line***** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <span style="color:red">* &nbsp;</span>
            <div>
              Add
              <el-popover placement="top" trigger="hover">
                <a
                  target="_blank"
                  href="https://page.line.me/ivyway"
                  class="line-link text-success"
                  slot="reference"
                >
                  <b>@ivyway</b>
                </a>
                <a target="_blank" href="https://page.line.me/ivyway">
                  <img width="140" height="140" :src="lineQRcode" />
                </a>
              </el-popover>
              on Line
              <el-popover placement="top" trigger="hover">
                <div>
                  <el-alert type="success" :closable="false" class="p-1">
                    <div>
                      Once you have a Line account, find us at @ivyway (with<br />
                      the @ symbol in the beginning) and send us a message.
                    </div>
                  </el-alert>
                </div>
                <span
                  class="text-success"
                  style="cursor:pointer"
                  slot="reference"
                >
                  <i class="fas fa-question-circle"></i
                ></span>
              </el-popover>
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0" prop="has_added_line" required>
              <el-checkbox
                style="margin-bottom:0"
                true-label="1"
                false-label=""
                v-model="newObjectDetail.has_added_line"
                >Done</el-checkbox
              >
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ***** Your online classroom link***** -->

        <el-row>
          <el-col :md="4" class="md-label">
            <span style="color:red">* &nbsp;</span>
            <el-popover placement="top" trigger="hover">
              <div>
                <el-alert type="success" :closable="false" class="p-1">
                  <div>
                    Log into your own Zoom or Google Meet account, create a
                    new<br />
                    room that is permanent, and paste that link here. You’ll
                    use<br />
                    that same link for all your online classes.<br />
                    The link should look like <b>https://zoom.us/s/1234</b
                    ><br />
                    or <b>https://meet.google.com/aaa-bbb-ccc</b>
                  </div>
                </el-alert>
              </div>
              <span
                class="text-success"
                style="cursor:pointer"
                slot="reference"
              >
                Your online classroom link
                <i class="fas fa-question-circle"></i
              ></span>
            </el-popover>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0" prop="meeting_link_url" required>
              <el-input
                placeholder="Your online classroom link"
                v-model="newObjectDetail.meeting_link_url"
                @focus="colorChangeSkype = true"
                @blur="colorChangeSkype = false"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- ******Bio1****** -->
        <el-row v-if="false">
          <el-col :md="4" class="md-label">
            <span style="color:red">* &nbsp;</span>
            <div>
              {{ $t("userProfile.teacher.ans_to_extracurriculars") }}
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item
              label-width="0"
              prop="ans_to_extracurriculars"
              required
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                :placeholder="
                  $t('userProfile.teacher.ans_to_extracurriculars_placeholder')
                "
                v-model="newObjectDetail.ans_to_extracurriculars"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ******Bio2****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <span style="color:red">* &nbsp;</span>
            <div>
              {{ $t("userProfile.teacher.ans_to_teaching_years") }}
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0" prop="ans_to_teaching_years" required>
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                :placeholder="
                  $t('userProfile.teacher.ans_to_teaching_years_placeholder')
                "
                v-model="newObjectDetail.ans_to_teaching_years"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ******Bio3****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <span style="color:red">* &nbsp;</span>
            <div>
              {{ $t("userProfile.teacher.ans_to_student_numbers") }}
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item
              label-width="0"
              prop="ans_to_student_numbers"
              required
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                :placeholder="
                  $t('userProfile.teacher.ans_to_student_numbers_placeholder')
                "
                v-model="newObjectDetail.ans_to_student_numbers"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- ******Bio4****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <span style="color:red">* &nbsp;</span>
            <div>
              {{ $t("userProfile.teacher.ans_to_capable_subjects") }}
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item
              label-width="0"
              prop="ans_to_capable_subjects"
              required
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                :placeholder="
                  $t('userProfile.teacher.ans_to_capable_subjects_placeholder')
                "
                v-model="newObjectDetail.ans_to_capable_subjects"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ******Bio5****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <span style="color:red">* &nbsp;</span>
            <div>
              {{ $t("userProfile.teacher.ans_to_relevant_work") }}
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0" prop="ans_to_relevant_work" required>
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                :placeholder="
                  $t('userProfile.teacher.ans_to_relevant_work_placeholder')
                "
                v-model="newObjectDetail.ans_to_relevant_work"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- ******Bio6****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <span style="color:red">* &nbsp;</span>
            <div>
              {{ $t("userProfile.teacher.ans_to_students_perform") }}
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item
              label-width="0"
              prop="ans_to_students_perform"
              required
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                :placeholder="
                  $t('userProfile.teacher.ans_to_students_perform_placeholder')
                "
                v-model="newObjectDetail.ans_to_students_perform"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row style="padding-bottom: 30px; padding-top: 20px">
          <el-col>
            <h1 class="titleLine primaryColor">
              {{ $t("teacherDetail.optionalInformation") }}
            </h1>
          </el-col>
        </el-row> -->

        <el-row style="padding-bottom: 20px; padding-top: 20px">
          <el-col>
            <h1 class="titleLine primaryColor">
              {{ $t("teacherDetail.profile") }}
            </h1>
          </el-col>
        </el-row>

        <!-- Tests -->
        <el-row>
          <el-col :md="4" class="md-label">
            {{ $t("teacherDetail.askTest") }}
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0">
              <el-select
                v-model="testsList"
                multiple
                :placeholder="$t('teacherDetail.pleaseSelect')"
                style="width:100%"
              >
                <el-option
                  v-for="(item, index) in tests"
                  :key="index"
                  :label="item.name.toUpperCase()"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- Show the tests -->
        <el-card
          v-if="testsList && testsList.length > 0"
          class="mb-4"
          shadow="never"
        >
          <el-row v-for="(test, index) in testsList" :key="index">
            <el-col v-if="test">
              <Tests
                :test="findTest(test)"
                @dataFromChild="updateTest"
                :isTeacher="true"
              />
            </el-col>
          </el-row>
        </el-card>

        <!-- ******Bio****** -->
        <!-- <el-form-item prop="bio" style="padding-top: 10px">
          <el-row>
            <el-col :md="6">
              <span>{{ $t("teacherDetail.bio") }}</span>
            </el-col>
            <el-col :md="15">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 6 }"
                :placeholder="$t('teacherDetail.bio')"
                v-model="newObjectDetail.bio.en"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item> -->

        <!-- ******Subject available to teach****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            {{ $t("teacherDetail.subjectsTeach") }}
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0">
              <el-select
                v-model="newObjectDetail.subjects"
                multiple
                filterable
                :placeholder="$t('teacherDetail.subjectsTeach')"
                style="width:100%"
              >
                <el-option-group
                  v-for="(group, index) in subjects"
                  :key="index"
                  :label="group.label"
                >
                  <el-option
                    v-for="(item, index) in group.options"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ******Hours available****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            {{ $t("teacherDetail.hoursAvailable") }}
          </el-col>
          <el-col :md="20">
            <el-form-item prop="hours_available" label-width="0">
              <el-input
                :placeholder="$t('teacherDetail.hoursAvailable')"
                v-model="newObjectDetail.hours_available"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ******Photo****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <el-popover placement="top" trigger="hover">
              <div>
                <el-alert type="success" :closable="false" class="p-1">
                  <div>
                    Please upload a good looking photo of you. It should be
                    <br />
                    business casual or professional, but don’t use your<br />
                    graduation photo (it’s boring). See samples<br />
                    on
                    <a target="_blank" href="https://ivy-way.com/instructors"
                      ><b>https://ivy-way.com/instructors</b></a
                    >
                  </div>
                </el-alert>
              </div>
              <span
                class="text-success"
                style="cursor:pointer"
                slot="reference"
              >
                {{ $t("teacherDetail.photoUpload") }}
                <i class="fas fa-question-circle"></i
              ></span>
            </el-popover>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                :action="imageAction"
                :headers="token"
                :on-preview="handlePreview"
                :on-success="handleUploadSuccess"
                :on-remove="handleUploadRemove"
                name="image"
              >
                <div class="el-upload__tip" slot="tip">
                  請上传 1:1 的照片，且不超过2M。
                </div>
                <img
                  v-if="newObjectDetail.photo"
                  :src="newObjectDetail.photo"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <!-- <el-button size="small" type="primary">
                  {{ $t("teacherDetail.upload") }}
                </el-button> -->
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <br />

        <el-row style="padding-bottom: 20px">
          <el-col>
            <h1 class="titleLine primaryColor">
              <el-popover placement="top" trigger="hover">
                <div>
                  <h6 class="text-success p-1 m-0">
                    This information is private for Ivy-Way to see only.
                  </h6>
                </div>
                <span
                  class="text-success"
                  style="cursor:pointer"
                  slot="reference"
                >
                  {{ $t("teacherDetail.billingInfo") }}
                  <i class="fas fa-user-shield"></i>
                </span>
              </el-popover>
            </h1>
          </el-col>
        </el-row>

        <!-- ******official name****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <div>
              <el-popover placement="top" trigger="hover">
                <div>
                  <el-alert type="success" :closable="false" class="p-1">
                    This is the name you use to file taxes.
                  </el-alert>
                </div>
                <span
                  class="text-success"
                  style="cursor:pointer"
                  slot="reference"
                >
                  {{ $t("teacherDetail.officalName") }}
                  <i class="fas fa-question-circle"></i
                ></span>
              </el-popover>
            </div>
          </el-col>
          <el-col :md="20">
            <el-form-item prop="official_name" label-width="0">
              <el-input
                :placeholder="$t('teacherDetail.officalName')"
                v-model="newObjectDetail.official_name"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ******Social Security Number****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <el-popover placement="top" trigger="hover">
              <div>
                <el-alert type="success" :closable="false" class="p-1">
                  We need this for your 1099-MISC form.
                </el-alert>
              </div>
              <span
                class="text-success"
                style="cursor:pointer"
                slot="reference"
              >
                {{ $t("teacherDetail.ssn") }}
                <i class="fas fa-question-circle"></i
              ></span>
            </el-popover>
          </el-col>
          <el-col :md="20">
            <el-form-item prop="ssn" label-width="0">
              <el-input
                type="password"
                :placeholder="$t('teacherDetail.ssn')"
                v-model="newObjectDetail.ssn"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ******Date of birth****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            {{ $t("teacherDetail.dateOfBirth") }}
          </el-col>
          <el-col :md="20">
            <el-form-item prop="dob" label-width="0">
              <el-date-picker
                v-model="newObjectDetail.dob"
                type="date"
                :placeholder="$t('teacherDetail.selectDate')"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ******Payment Method****** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <span>{{ $t("teacherDetail.paymentMethod") }}</span>
          </el-col>
          <el-col :md="20">
            <el-form-item prop="payment" label-width="0">
              <el-select
                v-model="newObjectDetail.payment.method"
                :placeholder="$t('teacherDetail.paymentMethod')"
                style="width:100%"
              >
                <el-option
                  v-for="(item, index) in paymentList"
                  :key="index"
                  :label="$t(item.label)"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ******Payment - USA****** -->
        <el-row v-if="newObjectDetail.payment.method === 'usa'">
          <el-col>
            <!-- <template v-if="false">
              <el-form-item style="padding-top: 10px">
                <el-row :gutter="10">
                  <el-col :md="6">
                    <span>&nbsp;</span>
                  </el-col>
                  <el-col :md="9">
                    <el-form-item style="padding-top: 10px">
                      <el-input
                        :placeholder="$t('payment.nameOnCheck')"
                        v-model="newObjectDetail.payment.us_name_on_check"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item style="padding-top: 10px">
                <el-row :gutter="10">
                  <el-col :md="6">
                    <span>{{ $t("payment.address") }}</span>
                  </el-col>
                  <el-col :md="9">
                    <el-form-item style="padding-top: 10px">
                      <el-input
                        :placeholder="$t('payment.address')"
                        v-model="newObjectDetail.payment.address1"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-row :gutter="10">
                <el-col :md="6">
                  <el-form-item></el-form-item>
                </el-col>
                <el-col :md="6">
                  <el-form-item style="padding-top: 5px;">
                    <el-input
                      :placeholder="$t('payment.city')"
                      v-model="newObjectDetail.payment.us_city"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="6">
                  <el-form-item style="padding-top: 5px">
                    <el-input
                      :placeholder="$t('payment.state')"
                      v-model="newObjectDetail.payment.us_state"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="6">
                  <el-form-item style="padding-top: 5px">
                    <el-input
                      :placeholder="$t('payment.zip')"
                      v-model="newObjectDetail.payment.us_zip"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </template> -->
            <el-row :gutter="10">
              <el-col :md="4">
                <span>&nbsp;</span>
              </el-col>
              <el-col :md="20">
                <el-form-item label-width="0">
                  <el-input
                    placeholder="Phone number or email for your Zelle"
                    v-model="newObjectDetail.payment.us_Bank_Zelle"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- ******Payment - Taiwan****** -->
        <el-row v-if="newObjectDetail.payment.method === 'taiwan'">
          <el-col>
            <el-row>
              <el-col :md="4">
                <span>&nbsp;</span>
              </el-col>
              <el-col :md="20">
                <el-form-item label-width="0">
                  <el-input
                    v-model="newObjectDetail.payment.taiwan_bank_name"
                    :placeholder="$t('payment.bankName')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="4">
                <span>&nbsp;</span>
              </el-col>
              <el-col :md="20">
                <el-form-item label-width="0">
                  <el-input
                    v-model="newObjectDetail.payment.taiwan_bank_branch"
                    :placeholder="$t('payment.branch')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="4">
                <span>&nbsp;</span>
              </el-col>
              <el-col :md="20">
                <el-form-item label-width="0">
                  <el-input
                    v-model="newObjectDetail.payment.taiwan_bank_holder_name"
                    :placeholder="$t('payment.holderName')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="4">
                <span>&nbsp;</span>
              </el-col>
              <el-col :md="20">
                <el-form-item label-width="0">
                  <el-input
                    v-model="newObjectDetail.payment.taiwan_bank_account_number"
                    :placeholder="$t('payment.account')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- ******Payment - Wechat****** -->
        <el-row v-if="newObjectDetail.payment.method === 'wechat'">
          <el-col :md="4">
            <span>&nbsp;</span>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0">
              <el-input
                v-model="newObjectDetail.payment.wechat_id"
                :placeholder="$t('payment.wechat_id')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ******Payment - Alipay****** -->
        <el-row v-if="newObjectDetail.payment.method === 'alipay'">
          <el-col :md="4">
            <span>&nbsp;</span>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0">
              <el-input
                v-model="newObjectDetail.payment.alipay_phone"
                :placeholder="$t('payment.alipayPhone')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- ******Mailing Address****** -->
        <el-row :gutter="10">
          <el-col :md="4" class="md-label">
            <el-popover placement="top" trigger="hover">
              <div>
                <el-alert type="success" :closable="false" class="p-1">
                  We need this for your 1099-MISC form.
                </el-alert>
              </div>
              <span
                class="text-success"
                style="cursor:pointer"
                slot="reference"
              >
                {{ $t("teacherDetail.mailingAddress") }}
                <i class="fas fa-question-circle"></i
              ></span>
            </el-popover>
          </el-col>
          <el-col :md="20">
            <el-row :gutter="10">
              <el-col :md="12">
                <el-form-item prop="address1" label-width="0">
                  <el-input
                    :placeholder="$t('teacherDetail.mailingAddressLine1')"
                    v-model="newObjectDetail.address1"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="12">
                <el-form-item prop="address2" label-width="0">
                  <el-input
                    :placeholder="$t('teacherDetail.mailingAddressLine2')"
                    v-model="newObjectDetail.address2"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :md="8">
                <el-form-item prop="city" label-width="0">
                  <el-input
                    :placeholder="$t('teacherDetail.city')"
                    v-model="newObjectDetail.city"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item prop="state" label-width="0">
                  <el-input
                    :placeholder="$t('teacherDetail.state')"
                    v-model="newObjectDetail.state"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item prop="zip_code" label-width="0">
                  <el-input
                    :placeholder="$t('teacherDetail.zipCode')"
                    v-model="newObjectDetail.zip_code"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!-- *****Facebook***** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <span>Facebook</span>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0">
              <el-input
                placeholder="Facebook"
                v-model="newObjectDetail.facebook_id"
                @focus="colorChangeFacebook = true"
                @blur="colorChangeFacebook = false"
              >
                <div
                  slot="prefix"
                  class="input-icon icon-facebook"
                  :style="
                    colorChangeFacebook
                      ? 'background-color: #42a16a;'
                      : 'background-color: #757575;'
                  "
                ></div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- *****Wechat***** -->
        <el-row>
          <el-col :md="4" class="md-label">
            <span>Wechat</span>
          </el-col>
          <el-col :md="20">
            <el-form-item label-width="0">
              <el-input
                placeholder="Wechat"
                v-model="newObjectDetail.wechat_id"
                @focus="colorChangeWechat = true"
                @blur="colorChangeWechat = false"
              >
                <div
                  slot="prefix"
                  class="input-icon icon-wechat"
                  :style="
                    colorChangeWechat
                      ? 'background-color: #42a16a;'
                      : 'background-color: #757575;'
                  "
                ></div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- *****Skype***** -->
        <!-- <el-form-item style="padding-top: 10px">
          <el-row>
            <el-col :md="4">
              <span>Skype</span>
            </el-col>
            <el-col :md="18">
              <el-input
                placeholder="Skype"
                v-model="newObjectDetail.skype_id"
                @focus="colorChangeSkype = true"
                @blur="colorChangeSkype = false"
              >
                <div
                  slot="prefix"
                  class="input-icon icon-skype"
                  :style="
                    colorChangeSkype
                      ? 'background-color: #42a16a;'
                      : 'background-color: #757575;'
                  "
                ></div>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item> -->

        <el-row>
          <el-col>
            <div align="center" justify="center">
              <el-button
                type="primary"
                style="width: 50%"
                @click="submitForm('newObjectDetail')"
                >{{ $t("teacherDetail.submit") }}</el-button
              >
            </div>
          </el-col>
        </el-row>
      </template>
    </el-form>
  </div>
</template>

<script>
import moment from "moment";
import Tests from "@/components/roleBinding/components/Tests";
import JwtService from "@/common/jwt.service";
import testHelper from "@/mixins/testHelper";
import gradeLevelMixin from "@/mixins/gradeLevel";
import formMixin from "@/mixins/form";
import subjectsData from "@/data/subjects.json";
import { mapState } from "vuex";

export default {
  name: "TeacherDetail",
  components: {
    Tests
  },
  mixins: [testHelper, gradeLevelMixin, formMixin],
  props: {
    profile: Object,
    teacherApplication: Object
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      const phoneReg = /^\d+$/;
      if (!value) {
        return callback(new Error(this.$t("teacherDetail.phoneRequiredError")));
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error(this.$t("teacherDetail.phoneNumberError")));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      lineQRcode: require("@/assets/qrcode/line_QR.jpg"),
      newObjectDetail: this.createObject(),
      graduateSelection: [],
      graduateSelected: "",
      instructorCode: "",
      colorChangeCode: false,
      colorChangeIsValid: false,
      colorChangeSchool: false,
      colorChangeGrade: false,
      colorChangeMajor: false,
      colorChangePhone: false,
      colorChangeAddress: false,
      colorChangeFacebook: false,
      colorChangeLine: false,
      colorChangeWechat: false,
      colorChangeSkype: false,
      fileList: [],
      testsList: [],
      tests: this.getCurrentTestArray([
        "SAT_teacher",
        "ACT",
        "AP",
        "IB",
        "TOEFL",
        "IELTS"
      ]),
      token: { Authorization: `Bearer ${JwtService.getToken()}` },
      uploadDisable: false,
      uploadFileId: "",
      authCodeError: null,
      rules: {
        college: [
          {
            required: true,
            message: this.$t("teacherDetail.collegeRequiredError")
          }
        ],
        college_major: [
          {
            required: true,
            message: this.$t("teacherDetail.majorRequiredError")
          }
        ],
        phone: [
          {
            required: true,
            message: this.$t("teacherDetail.phoneRequiredError")
          },
          { validator: checkPhone, trigger: "blur" }
        ],
        college_graduation_year: [
          {
            required: true,
            message: this.$t("validation.required")
          },
          { trigger: "blur" }
        ],
        line_id: [
          {
            required: true,
            message: this.$t("validation.required")
          },
          { trigger: "blur" }
        ],
        has_added_line: [
          {
            required: true,
            message: this.$t("validation.required")
          },
          { trigger: "blur" }
        ],
        ans_to_extracurriculars: [
          {
            required: true,
            message: this.$t("validation.required")
          },
          { trigger: "blur" }
        ],
        ans_to_teaching_years: [
          {
            required: true,
            message: this.$t("validation.required")
          },
          { trigger: "blur" }
        ],
        ans_to_student_numbers: [
          {
            required: true,
            message: this.$t("validation.required")
          },
          { trigger: "blur" }
        ],
        ans_to_capable_subjects: [
          {
            required: true,
            message: this.$t("validation.required")
          },
          { trigger: "blur" }
        ],
        ans_to_relevant_work: [
          {
            required: true,
            message: this.$t("validation.required")
          },
          { trigger: "blur" }
        ],
        ans_to_students_perform: [
          {
            required: true,
            message: this.$t("validation.required")
          },
          { trigger: "blur" }
        ],
        meeting_link_url: [
          {
            required: true,
            message: this.$t("validation.required")
          },
          { trigger: "blur" }
        ]
      },
      subjects: subjectsData,
      paymentList: [
        { label: "payment.bankUsa", value: "usa" },
        { label: "payment.bankTaiwan", value: "taiwan" },
        { label: "payment.wechat", value: "wechat" },
        { label: "payment.alipay", value: "alipay" }
      ]
    };
  },
  created() {
    // clean up the event object
    this.newObjectDetail = this.createObject();
    this.graduateSelection = this.createTeacherGraduateSelection();
    const month = moment()
      .format()
      .substring(5, 7);

    if (parseInt(month) < 7)
      this.graduateSelected = this.graduateSelection[13].value;
    else this.graduateSelected = this.graduateSelection[13].value;
  },
  methods: {
    updateTest(value) {
      const objIndex = this.tests.findIndex(obj => obj.name === value.name);
      this.$set(this.tests, objIndex, value);
    },
    createObject() {
      const id = this.profile.id;
      const username = this.profile.username;
      const email = this.profile.email;
      const first_name = this.profile.first_name;
      const last_name = this.profile.last_name;
      return {
        user_id: id,
        username: username,
        email: email,
        first_name: first_name,
        last_name: last_name,

        college: {
          en: "",
          tw: "",
          cn: ""
        },
        college_graduation_year: "",
        college_major: {
          en: "",
          tw: "",
          cn: ""
        },
        phone: "",
        scores: {
          sat: [
            {
              english_score: "",
              math_score: "",
              writing_score: "",
              taken_on: "",
              is_real_test: 1
            }
          ],
          act: [
            {
              english_score: "",
              math_score: "",
              science_score: "",
              reading_score: "",
              writing_score: "",
              taken_on: "",
              is_real_test: 1
            }
          ],
          ap: [],
          ib: [],
          toefl: [
            {
              reading_score: "",
              listening_score: "",
              speaking_score: "",
              writing_score: "",
              taken_on: "",
              is_real_test: 1
            }
          ],
          ielts: [
            {
              reading_score: "",
              listening_score: "",
              speaking_score: "",
              writing_score: "",
              taken_on: "",
              is_real_test: 1
            }
          ]
        },

        bio: {
          en: "",
          tw: "",
          cn: ""
        },
        subjects: [],
        hours_available: "",
        photo: "",

        official_name: "",
        ssn: "",
        dob: "",
        payment: {},
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip_code: "",

        facebook_id: "",
        line_id: "",
        wechat_id: "",
        skype_id: "",
        ans_to_extracurriculars: "",
        ans_to_teaching_years: "",
        ans_to_student_numbers: "",
        ans_to_capable_subjects: "",
        ans_to_relevant_work: "",
        ans_to_students_perform: "",
        has_added_line: "",
        meeting_link_url: ""
      };
    },
    /*eslint-disable no-unused-vars */
    handlePreview(file) {},
    /*eslint-enable */

    handleUploadSuccess(response, file, fileList) {
      this.newObjectDetail.photo = file.response.image.url;
      if (this.uploadFileId) this.handleUploadRemove(file, fileList);
      else this.fileList = fileList;
      this.uploadFileId = fileList[0].response.image.id;
    },
    async handleUploadRemove(file, fileList) {
      await this.$store.dispatch(
        "registerDetails/deleteUploadFile",
        this.uploadFileId
      );

      if (fileList.length >= 2) {
        this.fileList = fileList.slice(1, 2);
      } else {
        this.fileList = [];
        this.uploadFileId = "";
      }
    },
    async authCode() {
      await this.$store.dispatch("registerDetails/checkInstructorAuth", {
        code: this.instructorCode
      });
      if (this.isAuthCode) {
        this.authCodeError = null;
        this.colorChangeIsValid = true;
      } else {
        this.authCodeError = "Unauthorized";
        this.colorChangeIsValid = false;
      }
    },
    submitForm(formName) {
      // assign data to high_school_graduation_year
      this.newObjectDetail.college_graduation_year = this.graduateSelected;
      // assign data to tests
      this.tests.forEach(obj => {
        switch (obj.name) {
          case "sat":
            this.newObjectDetail.scores.sat[0].english_score =
              obj.english_score;
            this.newObjectDetail.scores.sat[0].math_score = obj.math_score;
            this.newObjectDetail.scores.sat[0].writing_score =
              obj.writing_score;
            this.newObjectDetail.scores.sat[0].taken_on = obj.taken_on;
            this.newObjectDetail.scores.sat[0].is_real_test = obj.is_real_test;
            break;
          case "act":
            this.newObjectDetail.scores.act[0].english_score =
              obj.english_score;
            this.newObjectDetail.scores.act[0].math_score = obj.math_score;
            this.newObjectDetail.scores.act[0].science_score =
              obj.science_score;
            this.newObjectDetail.scores.act[0].reading_score =
              obj.reading_score;
            this.newObjectDetail.scores.act[0].writing_score =
              obj.writing_score;
            this.newObjectDetail.scores.act[0].taken_on = obj.taken_on;
            this.newObjectDetail.scores.act[0].is_real_test = obj.is_real_test;
            break;
          case "ap":
            this.newObjectDetail.scores.ap = obj.items.filter(
              item => item.subject
            );
            break;
          case "ib":
            this.newObjectDetail.scores.ib = obj.items.filter(
              item => item.subject
            );
            break;
          case "toefl":
            this.newObjectDetail.scores.toefl[0].reading_score =
              obj.reading_score;
            this.newObjectDetail.scores.toefl[0].listening_score =
              obj.listening_score;
            this.newObjectDetail.scores.toefl[0].speaking_score =
              obj.speaking_score;
            this.newObjectDetail.scores.toefl[0].writing_score =
              obj.writing_score;
            this.newObjectDetail.scores.toefl[0].taken_on = obj.taken_on;
            this.newObjectDetail.scores.toefl[0].is_real_test =
              obj.is_real_test;
            break;
          case "ielts":
            this.newObjectDetail.scores.ielts[0].reading_score =
              obj.reading_score;
            this.newObjectDetail.scores.ielts[0].listening_score =
              obj.listening_score;
            this.newObjectDetail.scores.ielts[0].speaking_score =
              obj.speaking_score;
            this.newObjectDetail.scores.ielts[0].writing_score =
              obj.writing_score;
            this.newObjectDetail.scores.ielts[0].taken_on = obj.taken_on;
            this.newObjectDetail.scores.ielts[0].is_real_test =
              obj.is_real_test;
            break;
        }
      });
      this.$refs[formName].validate(async valid => {
        const isError = document.getElementsByClassName("is-error");
        if (valid && !this.isDuplicate && isError.length === 0) {
          await this.$store.dispatch(
            "registerDetails/createNewTeacher",
            this.newObjectDetail
          );

          if (!this.isSubmitError) {
            this.$message({
              message: this.$t("message.teacherBindingSuccess"),
              type: "success"
            });
            this.$router.push({ name: "Home" });
          }
        } else {
          setTimeout(() => {
            let target = isError[0].querySelector("input");
            target.scrollIntoView({
              block: "center",
              behavior: "smooth"
            });
          }, 100);
          return false;
        }
      });
    }
  },
  watch: {
    teacherApplication: {
      immediate: true,
      deep: true,
      handler(newData) {
        if (newData) {
          this.newObjectDetail.college.en = this.teacherApplication.school;
          this.newObjectDetail.college_major.en = this.teacherApplication.major;
          this.newObjectDetail.phone = this.teacherApplication.phone;
          this.newObjectDetail.ans_to_extracurriculars = this.teacherApplication.ans_to_extracurriculars;
          this.newObjectDetail.ans_to_teaching_years = this.teacherApplication.ans_to_teaching_years;
          this.newObjectDetail.ans_to_student_numbers = this.teacherApplication.ans_to_student_numbers;
          this.newObjectDetail.ans_to_capable_subjects = this.teacherApplication.ans_to_capable_subjects;
          this.newObjectDetail.ans_to_relevant_work = this.teacherApplication.ans_to_relevant_work;
          this.newObjectDetail.ans_to_students_perform = this.teacherApplication.ans_to_students_perform;
          this.testsList = ["SAT_teacher", "ACT", "AP", "IB", "TOEFL", "IELTS"];
          this.newObjectDetail.tests = JSON.parse(
            this.teacherApplication.tests
          );
        }
      }
    },
    profile: {
      immediate: true,
      deep: true,
      handler(newData) {
        this.newObjectDetail.username = newData.username;
        this.newObjectDetail.email = newData.email;
        this.newObjectDetail.first_name = newData.first_name;
        this.newObjectDetail.last_name = newData.last_name;
      }
    }
  },
  computed: {
    imageAction() {
      return process.env.VUE_APP_BACKEND_URL + "image";
    },
    ...mapState("registerDetails", ["isAuthCode", "isSubmitError"]),
    ...mapState("user", ["isDuplicate"])
  }
};
</script>

<style scoped>
::v-deep .el-upload__tip {
  margin: 0;
  line-height: 1.5;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.titleLine {
  text-align: center;
  font-size: 22px;
}
.primaryColor {
  color: #42a16a;
}
.input-icon {
  width: 25px;
  height: 25px;
  margin-top: 7px;
  background-size: cover;
}
.icon-card {
  mask: url("../../assets/svg/card-account-details.svg") no-repeat;
}
.icon-domain {
  mask: url("../../assets/svg/domain.svg") no-repeat;
}
.icon-school {
  mask: url("../../assets/svg/school.svg") no-repeat;
}
.icon-major {
  mask: url("../../assets/svg/bookshelf.svg") no-repeat;
}
.icon-phone {
  mask: url("../../assets/svg/phone.svg") no-repeat;
}
.icon-clip {
  mask: url("../../assets/svg/paperclip.svg") no-repeat;
}
.icon-facebook {
  mask: url("../../assets/svg/facebook.svg") no-repeat;
}
.icon-line {
  mask: url("../../assets/svg/line-brands.svg") no-repeat;
}
.icon-wechat {
  mask: url("../../assets/svg/wechat.svg") no-repeat;
}
.icon-skype {
  mask: url("../../assets/svg/skype.svg") no-repeat;
}
.icon-check {
  mask: url("../../assets/svg/check-circle.svg") no-repeat;
}
.line-link {
  cursor: pointer;
}
.md-label {
  padding-right: 10px;
  min-height: 20px;
  line-height: 20px;
  display: flex;
  margin: 10px 0;
  /* align-items: center; */
  /* line-height: 30px; */
  /* justify-content:center; */
}
.md-label > span {
  display: inline-block;
  padding-right: 5px;
}
</style>

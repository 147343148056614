<template>
  <div style="margin:0px">
    <CounselingSchoolTypeDialog
      :visible="counselingSchoolsTypeDialigVisible"
      @toggleDialogVisible="
        visible => (counselingSchoolsTypeDialigVisible = visible)
      "
      @submit="
        newCounselingSchoolsType => {
          counselingSchoolsTypeDialigVisible = false;
          newObjectDetail.counseling_schools_type = newCounselingSchoolsType;
        }
      "
    />
    <el-form
      :model="newObjectDetail"
      :rules="rules"
      ref="form"
      label-width="auto"
      @submit.native.prevent
    >
      <!-- <el-row>
        <el-col>
          <h1 class="titleLine primaryColor">
            {{ $t("studentDetail.requiredInformation") }}
          </h1>
        </el-col>
      </el-row> -->
      <el-row>
        <el-col :md="4" class="md-label">
          <div>
            <span style="color:red">* &nbsp;</span>
            {{ $t("studentDetail.AccountType") }}
          </div>
        </el-col>
        <el-col :md="20">
          <el-form-item label-width="0">
            <el-radio-group
              style="width: 100%"
              :value="newObjectDetail.counseling_schools_type"
              @input="
                value => (newObjectDetail.counseling_schools_type = value)
              "
            >
              <el-radio :label="1" border>{{
                $t("studentDetail.toCollege")
              }}</el-radio>
              <el-radio :label="2" border>{{
                $t("studentDetail.toGradSchool")
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- *****School***** -->
      <el-row>
        <el-col el-col :md="4" class="md-label">
          <div>
            <span style="color:red">* &nbsp;</span>
            {{ $t("studentDetail.school") }}
          </div>
        </el-col>
        <el-col :md="20">
          <el-form-item label-width="0" prop="high_school" required>
            <el-input
              :placeholder="$t('studentDetail.school')"
              v-model="newObjectDetail.high_school"
              @focus="colorChangeSchool = true"
              @blur="colorChangeSchool = false"
            >
              <div
                slot="prefix"
                class="input-icon icon-domain"
                :style="
                  colorChangeSchool
                    ? 'background-color: #42a16a;'
                    : 'background-color: #757575;'
                "
              ></div>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- *****Grade level***** -->
      <el-row>
        <el-col el-col :md="4" class="md-label">
          <span style="color:red">* &nbsp;</span>
          <div>
            {{ $t("studentDetail.gradeLevel") }}
          </div>
        </el-col>
        <el-col :md="20">
          <el-form-item label-width="0" required>
            <GradeLevelSelector
              :value="graduateSelected"
              @change="
                value => {
                  graduateSelected = value;
                }
              "
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- *****Phone***** -->
      <el-row>
        <el-col el-col :md="4" class="md-label">
          <span style="color:red">* &nbsp;</span>
          <div>
            {{ $t("studentDetail.phone") }}
          </div>
        </el-col>
        <el-col :md="20">
          <el-form-item prop="phone" label-width="0">
            <el-input
              :placeholder="$t('studentDetail.phone')"
              v-model="newObjectDetail.phone"
              @focus="colorChangePhone = true"
              @blur="colorChangePhone = false"
            >
              <div
                slot="prefix"
                class="input-icon icon-phone"
                :style="
                  colorChangePhone
                    ? 'background-color: #42a16a;'
                    : 'background-color: #757575;'
                "
              ></div>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <h1 class="titleLine primaryColor">
            {{ $t("studentDetail.optionalInformation") }}
          </h1>
        </el-col>
      </el-row>

      <!-- *****Address***** -->
      <el-row>
        <el-col el-col :md="4" class="md-label">
          <div>
            {{ $t("studentDetail.address") }}
          </div>
        </el-col>
        <el-col el-col :md="20">
          <el-form-item label-width="0">
            <el-input
              :placeholder="$t('studentDetail.address')"
              v-model="newObjectDetail.living"
              @focus="colorChangeAddress = true"
              @blur="colorChangeAddress = false"
            >
              <div
                slot="prefix"
                class="input-icon icon-home"
                :style="
                  colorChangeAddress
                    ? 'background-color: #42a16a;'
                    : 'background-color: #757575;'
                "
              ></div>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- *****Facebook***** -->
      <el-row>
        <el-col el-col :md="4" class="md-label">
          <div>
            Facebook
          </div>
        </el-col>
        <el-col el-col :md="20">
          <el-form-item label-width="0">
            <el-input
              placeholder="Facebook"
              v-model="newObjectDetail.facebook_id"
              @focus="colorChangeFacebook = true"
              @blur="colorChangeFacebook = false"
            >
              <div
                slot="prefix"
                class="input-icon icon-facebook"
                :style="
                  colorChangeFacebook
                    ? 'background-color: #42a16a;'
                    : 'background-color: #757575;'
                "
              ></div>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- *****Line***** -->
      <el-row>
        <el-col el-col :md="4" class="md-label">
          <div>
            Line
          </div>
        </el-col>
        <el-col el-col :md="20">
          <el-form-item label-width="0">
            <el-input
              placeholder="Line"
              v-model="newObjectDetail.line_id"
              @focus="colorChangeLine = true"
              @blur="colorChangeLine = false"
            >
              <div
                slot="prefix"
                class="input-icon icon-line"
                :style="
                  colorChangeLine
                    ? 'background-color: #42a16a;'
                    : 'background-color: #757575;'
                "
              ></div>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- *****Wechat***** -->
      <el-row>
        <el-col el-col :md="4" class="md-label">
          <div>
            Wechat
          </div>
        </el-col>
        <el-col el-col :md="20">
          <el-form-item label-width="0">
            <el-input
              placeholder="Wechat"
              v-model="newObjectDetail.wechat_id"
              @focus="colorChangeWechat = true"
              @blur="colorChangeWechat = false"
            >
              <div
                slot="prefix"
                class="input-icon icon-wechat"
                :style="
                  colorChangeWechat
                    ? 'background-color: #42a16a;'
                    : 'background-color: #757575;'
                "
              ></div>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- *****Skype***** -->
      <!-- <el-row>
        <el-col el-col :md="4" class="md-label">
          <div>
            Skype
          </div>
        </el-col>
        <el-col el-col :md="20">
          <el-form-item label-width="0">
            <el-input
              placeholder="Skype"
              v-model="newObjectDetail.skype_id"
              @focus="colorChangeSkype = true"
              @blur="colorChangeSkype = false"
            >
              <div
                slot="prefix"
                class="input-icon icon-skype"
                :style="
                  colorChangeSkype
                    ? 'background-color: #42a16a;'
                    : 'background-color: #757575;'
                "
              ></div>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row> -->

      <el-row>
        <el-col>
          <div align="center" justify="center">
            <el-button
              type="primary"
              style="width: 50%"
              @click="submitForm('form')"
              >{{ $t("studentDetail.submit") }}</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GradeLevelSelector } from "@/components/selector";
import CounselingSchoolTypeDialog from "@/components/counselingSchoolsType/CounselingSchoolTypeDialog";
import testHelper from "@/mixins/testHelper";
import formMixin from "@/mixins/form";
import gradeLevelMixin from "@/mixins/gradeLevel";
import moment from "moment";

export default {
  name: "StudentDetail",
  components: {
    GradeLevelSelector,
    CounselingSchoolTypeDialog
  },
  mixins: [testHelper, formMixin, gradeLevelMixin],
  props: {
    profile: Object
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      const phoneReg = /^\d+$/;
      if (!value) {
        return callback(new Error(this.$t("studentDetail.phoneRequiredError")));
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error(this.$t("studentDetail.phoneNumberError")));
        } else {
          callback();
        }
      }, 100);
    };

    return {
      counselingSchoolsTypeDialigVisible: true,
      newObjectDetail: this.createObject(),
      graduateSelected: "",
      colorChangeSchool: false,
      colorChangePhone: false,
      colorChangeAddress: false,
      colorChangeFacebook: false,
      colorChangeLine: false,
      colorChangeWechat: false,
      colorChangeSkype: false,

      rules: {
        high_school: [
          {
            required: true,
            message: this.$t("studentDetail.schoolRequiredError")
          }
        ],
        phone: [
          {
            required: true,
            message: this.$t("studentDetail.phoneRequiredError")
          },
          { validator: checkPhone, trigger: "blur" }
        ]
      }
    };
  },
  created() {
    // clean up the event object
    this.newObjectDetail = this.createObject();
  },
  methods: {
    createObject() {
      const id = this.profile.id;
      const username = this.profile.username;
      const email = this.profile.email;
      const first_name = this.profile.first_name;
      const last_name = this.profile.last_name;
      return {
        user_id: id,
        username: username,
        email: email,
        first_name: first_name,
        last_name: last_name,
        counseling_schools_type: null,
        high_school: "",
        high_school_graduation_year: "",
        phone: "",

        living: "",
        facebook_id: "",
        line_id: "",
        wechat_id: "",
        skype_id: ""
      };
    },

    submitForm(formName) {
      // assign data to high_school_graduation_year
      this.newObjectDetail.high_school_graduation_year = this.graduateSelected;

      this.$refs[formName].validate(async valid => {
        const isError = document.getElementsByClassName("is-error");
        if (valid && !this.isDuplicate && isError.length === 0) {
          await this.$store.dispatch(
            "registerDetails/createNewStudent",
            this.newObjectDetail
          );

          if (!this.isSubmitError) {
            this.$message({
              message: this.$t("message.studentBindingSuccess"),
              type: "success"
            });
            this.$router.push({ name: "Home" });
          }
        } else {
          setTimeout(() => {
            const isError = document.getElementsByClassName("is-error");
            const target = isError[0].querySelector("input");
            target.scrollIntoView({
              block: "center",
              behavior: "smooth"
            });
          }, 100);
          return false;
        }
      });
    }
  },
  computed: {
    ...mapState("registerDetails", ["isSubmitError"]),
    ...mapState("user", ["isDuplicate"])
  },
  watch: {
    profile: {
      immediate: true,
      deep: true,
      handler(newData) {
        this.newObjectDetail.username = newData.username;
        this.newObjectDetail.email = newData.email;
        this.newObjectDetail.first_name = newData.first_name;
        this.newObjectDetail.last_name = newData.last_name;
      }
    },
    "newObjectDetail.counseling_schools_type"() {
      const { counseling_schools_type } = this.newObjectDetail;
      const year = Number(moment().format("YYYY"));
      const month = Number(moment().format("M"));
      // 升大學
      if (counseling_schools_type === 1) {
        if (month < 7) {
          this.graduateSelected = String(year + 1);
        } else {
          this.graduateSelected = String(year + 1 + 1);
        }
        // 升研究所
      } else if (counseling_schools_type === 2) {
        if (month < 7) {
          this.graduateSelected = String(year - 1);
        } else {
          this.graduateSelected = String(year - 1 + 1);
        }
      }
    }
  }
};
</script>

<style scoped>
.titleLine {
  text-align: center;
  font-size: 30px;
}
.primaryColor {
  color: #42a16a;
}
.input-icon {
  width: 22px;
  height: 22px;
  margin-top: 7px;
  background-size: cover;
}
.icon-domain {
  mask: url("../../assets/svg/domain.svg") no-repeat;
}
.icon-phone {
  mask: url("../../assets/svg/phone.svg") no-repeat;
}
.icon-home {
  mask: url("../../assets/svg/home.svg") no-repeat;
}
.icon-facebook {
  mask: url("../../assets/svg/facebook.svg") no-repeat;
}
.icon-line {
  mask: url("../../assets/svg/line-brands.svg") no-repeat;
}
.icon-wechat {
  mask: url("../../assets/svg/wechat.svg") no-repeat;
}
.icon-skype {
  mask: url("../../assets/svg/skype.svg") no-repeat;
}
.md-label {
  padding-right: 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
  /* justify-content:center; */
}
.md-label > span {
  display: inline-block;
  padding-right: 5px;
}
</style>

<template>
  <el-container style="margin-top: 20px">
    <el-card class="box-card">
      <div>
        <div class="titleStyle">
          <h1 class="primaryColor" style="font-size: 30px;">
            Hello! {{ profile.username }}
          </h1>
          <el-divider></el-divider>
        </div>
        <div style="margin:0">
          <el-form
            :model="profile"
            :rules="rules"
            ref="profile"
            label-width="auto"
          >
            <el-row>
              <el-col :md="4" class="md-label">
                <span style="color:red">* &nbsp;</span>
                {{ $t("registerDetail.type") }}
              </el-col>
              <el-col :md="20">
                <el-form-item prop="type" label-width="0px">
                  <el-select
                    v-model="type"
                    :placeholder="$t('registerDetail.pleaseSelect')"
                    style="width:100%"
                  >
                    <el-option
                      v-for="(item, index) in types"
                      :key="index"
                      :label="$t(item.lebel)"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="type === 'teacher'" :gutter="20">
              <el-col :md="4" class="md-label">
                <span style="color:red">* &nbsp;</span>
                {{ $t("teacherDetail.instructorCode") }}
              </el-col>
              <el-col :md="16">
                <el-form-item label-width="0px" :error="authCodeError">
                  <el-input
                    :placeholder="$t('teacherDetail.instructorCode')"
                    v-model="instructorCode"
                    @focus="colorChangeCode = true"
                    @blur="colorChangeCode = false"
                  >
                    <div
                      slot="prefix"
                      class="input-icon icon-card"
                      :style="
                        colorChangeCode
                          ? 'background-color: #42a16a;'
                          : 'background-color: #757575;'
                      "
                    ></div>
                    <div
                      slot="suffix"
                      class="input-icon icon-check"
                      :style="
                        colorChangeAuthCodeIsValid
                          ? 'background-color: #4CAF50;'
                          : 'background-color: white;'
                      "
                    ></div>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :md="4">
                <el-button
                  type="primary"
                  @click="authCode"
                  style="width:100%"
                  >{{ $t("teacherDetail.validate") }}</el-button
                >
              </el-col>
            </el-row>
            <hr />
            <template
              v-if="
                (type && type !== 'teacher') ||
                  (type === 'teacher' && isAuthCode)
              "
            >
              <el-row :gutter="20">
                <el-col :md="4" class="md-label">
                  <span style="color:red">* &nbsp;</span>
                  {{ $t("registerDetail.username") }}
                </el-col>
                <el-col :md="20">
                  <el-form-item
                    prop="username"
                    label-width="0px"
                    :error="usernameError"
                  >
                    <el-input
                      :placeholder="$t('registerDetail.username')"
                      v-model="profile.username"
                      @focus="colorChangeUsername = true"
                      @input="colorChangeIsValid = false"
                      @blur="
                        colorChangeUsername = false;
                        checkUsername();
                      "
                    >
                      <div
                        slot="prefix"
                        class="input-icon icon-account"
                        :style="
                          colorChangeUsername
                            ? 'background-color: #42a16a;'
                            : 'background-color: #757575;'
                        "
                      ></div>
                      <div
                        slot="suffix"
                        class="input-icon icon-check"
                        :style="
                          colorChangeIsValid
                            ? 'background-color: #4CAF50;'
                            : 'background-color: white;'
                        "
                      ></div>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :md="4" class="md-label">
                  <span style="color:red">* &nbsp;</span>
                  {{ $t("registerDetail.email") }}
                </el-col>
                <el-col :md="20">
                  <el-form-item prop="email" label-width="0px">
                    <el-input
                      :placeholder="$t('registerDetail.email')"
                      v-model="profile.email"
                      :disabled="true"
                    >
                      <div
                        slot="prefix"
                        class="input-icon icon-email"
                        :style="
                          colorChangeEmail
                            ? 'background-color: #42a16a;'
                            : 'background-color: #757575;'
                        "
                      ></div>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :md="4" class="md-label">
                  <span style="color:red">* &nbsp;</span>
                  {{ $t("registerDetail.firstName") }}
                </el-col>
                <el-col :md="8">
                  <el-form-item prop="first_name" label-width="0px">
                    <el-input
                      :placeholder="$t('registerDetail.firstNamePlaceholder')"
                      v-model="profile.first_name"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="4" class="md-label">
                  <span style="color:red">* &nbsp;</span>
                  {{ $t("registerDetail.lastName") }}
                </el-col>
                <el-col :md="8">
                  <el-form-item label-width="0px" prop="last_name">
                    <el-input
                      :placeholder="$t('registerDetail.lastNamePlaceholder')"
                      v-model="profile.last_name"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </el-form>
          <el-row v-if="type === 'student'">
            <el-col>
              <StudentDetail :profile="profile" />
            </el-col>
          </el-row>
          <el-row v-if="type === 'teacher'">
            <el-col>
              <TeacherDetail :profile="profile" :teacherApplication="teacherApplication" />
            </el-col>
          </el-row>
          <el-row v-if="type === 'parent'">
            <el-col>
              <ParentDetail :profile="profile" />
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import JwtService from "@/common/jwt.service";
import StudentDetail from "@/components/roleBinding/StudentDetail";
import TeacherDetail from "@/components/roleBinding/TeacherDetail";
import ParentDetail from "@/components/roleBinding/ParentDetail";
import formMixin from "@/mixins/form";
import ApiService from "@/common/api.service";

export default {
  components: {
    StudentDetail,
    TeacherDetail,
    ParentDetail
  },
  mixins: [formMixin],
  async mounted() {
    // 在 RegisterDetail 頁面檢查如果已經 Binding 完成，則導回 Home 頁面
    const isBinding = await ApiService.post("finish-bind");

    if (isBinding.finishBind) {
      this.$router.push({
        name: "Home"
      });
    }

    await this.$store.dispatch("user/getProfile");
    this.checkUsername();
  },
  data() {
    return {
      teacherApplication: null,
      instructorCode: "",
      authCodeError: null,
      colorChangeCode: false,
      colorChangeAuthCodeIsValid: null,
      type: "",
      colorChangeUsername: false,
      colorChangeEmail: false,
      colorChangeIsValid: false,
      types: [
        { lebel: "registerDetail.student", value: "student" },
        { lebel: "registerDetail.teacher", value: "teacher" },
        { lebel: "registerDetail.parent", value: "parent" }
      ],
      usernameError: null,
      tokenInput: { Authorization: `Bearer ${JwtService.getToken()}` }
    };
  },
  methods: {
    async authCode() {
      await this.$store.dispatch("registerDetails/checkInstructorAuth", {
        code: this.instructorCode
      });
      if (this.isAuthCode) {
        this.authCodeError = null;
        this.colorChangeAuthCodeIsValid = true;
        const result = await ApiService.query("teacher-application-by-email", {email: this.profile.email});
        this.teacherApplication = result.teacher_application;
        if(this.teacherApplication){
          this.profile.first_name = this.teacherApplication.first_name;
          this.profile.last_name = this.teacherApplication.last_name;
          // this.profile.last_name = this.teacherApplication.school;
        }
      } else {
        this.authCodeError = "Unauthorized";
        this.colorChangeAuthCodeIsValid = false;
      }
    },
    async checkUsername() {
      const username = this.profile.username;
      if (username) {
        await this.$store.dispatch("user/checkUsername", {
          username: username
        });

        if (this.isDuplicate) {
          // give a random value to usernameError and use $nextTick to avoid the problem that
          // the same error will not appear at bottom of input
          this.usernameError = Math.random().toString();
          this.$nextTick(() => {
            this.usernameError = this.$t(
              "registerDetail.usernameDuplicatedError"
            );
          });
          this.colorChangeIsValid = false;
        } else {
          this.usernameError = null;
          this.colorChangeIsValid = true;
        }
      }
    }
  },
  computed: {
    ...mapState("user", ["profile", "isDuplicate", "token"]),
    ...mapState("registerDetails", ["isAuthCode", "isBinding"]),
    rules() {
      return {
        first_name: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        username: [
          {
            required: true,
            message: this.$t("registerDetail.usernameRequiredError")
          }
        ]
      };
    }
  },
  watch: {}
};
</script>

<style scoped>
.titleLine {
  text-align: center;
}
.titleStyle {
  display: block;
  overflow: auto;
}
.primaryColor {
  color: #42a16a;
}
.select {
  font-size: 30px;
}
.textField {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.box-card {
  display: inline-block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.input-icon {
  width: 22px;
  height: 22px;
  margin-top: 7px;
  background-size: cover;
}
.icon-card {
  mask: url("../assets/svg/card-account-details.svg") no-repeat;
}
.icon-account {
  mask: url("../assets/svg/account.svg") no-repeat;
}
.icon-email {
  mask: url("../assets/svg/email.svg") no-repeat;
}
.icon-check {
  mask: url("../assets/svg/check-circle.svg") no-repeat;
}
.md-label {
  min-height: 40px;
  display: flex;
  align-items: center;
  /* justify-content:center; */
}
</style>
